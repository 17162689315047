<template>
  <div>
    <div class="bg-f5">
      <div class="rounded-lg shadow-lg mt-5">
        <div class="flex justify-between items-center py-2 px-8 bg-f5">
          <img src="@/assets/starinvestama.png" class="h-6" alt="" />
          <h3 class="font-semibold text-lg">Rp 10.000/1 day</h3>
        </div>
        <div class="flex justify-between items-center px-8 bg-white">
          <div class="">
            <h3 class="my-2 text-primary text-base font-semibold">Personal Accident</h3>
            <div class="bg-f5 py-2 px-3 flex items-center rounded-lg">
              <p class="font-medium mr-12 text-sm">See Benefit</p>
              <button class="flex items-center p-2" @click="openBenefit()">
                <font-awesome-icon icon="fa-solid fa-chevron-down" />
              </button>
            </div>
          </div>
          <div class="">
            <button class="py-1.5 px-6 border border-c2 font-medium rounded-lg mr-5 text-sm">More Info</button>
            <button class="py-1.5 px-8 font-medium rounded-lg bg-primary text-white text-sm">Select</button>
          </div>
        </div>
      </div>
      <div class="px-8 py-6" :class="[benefitReady ? 'block' : 'hidden']">
        <div class="border-b border-c2">
          <div class="flex justify-between text-xs font-medium pt-2.5 pb-4">
            <p>Death and Disability Due to Personal Accident Compensation</p>
            <p>Coverage up to Rp 10.000.000</p>
          </div>
        </div>
        <div class="border-b border-c2">
          <div class="flex justify-between text-xs font-medium pt-2.5 pb-4">
            <p>Death and Disability Due to Personal Accident Compensation</p>
            <p>Coverage up to Rp 10.000.000</p>
          </div>
        </div>
        <div class="border-c2">
          <div class="flex justify-between text-xs font-medium pt-2.5 pb-4">
            <p>Death and Disability Due to Personal Accident Compensation</p>
            <p>Coverage up to Rp 10.000.000</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'InsuranceList',
    components: {
    },
    data() {
      return {
        benefitReady: false,
      }
    },
    methods: {
      openBenefit() {
        this.benefitReady = !this.benefitReady
      },
    },
  };
  </script>
  