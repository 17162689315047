<template>
  <div>
    <div class="container max-w-screen-lg item-center mx-auto" id="insurance-list">
      <div class="py-8 px-36">
        <img src="@/assets/starinvestama.png" class="w-52 mx-auto" alt="" />
        <p class="text-center font-medium mt-1">Personal Accident by Starinvestama Insurance Indonesia</p>
        <p class="text-center font-medium mt-2 w-full py-0.5 bg-yellow">Here are the benefits of Personal Accident by
          Starinvestama Insurance Indonesia;</p>
        <div class="flex flex-wrap w-full mt-2 px-12">
          <div class="w-1/3 px-3">
            <p class="text-sm font-medium my-2">Start Date</p>
            <v-date-picker model="date" ref="riderDobRef">
              <template v-slot="{ inputValue, inputEvents }">
                <input type="text" :value="inputValue" v-on="inputEvents"
                  class="border border-b4 text-sm rounded-lg w-full block p-2.5" placeholder="dd/mm/yy">
              </template>
            </v-date-picker>
          </div>
          <div class="w-1/3 px-3">
            <p class="text-sm font-medium my-2">End Date</p>
            <v-date-picker model="date" ref="riderDobRef">
              <template v-slot="{ inputValue, inputEvents }">
                <input type="text" :value="inputValue" v-on="inputEvents"
                  class="border border-b4 text-sm rounded-lg w-full block p-2.5" placeholder="dd/mm/yy">
              </template>
            </v-date-picker>
          </div>
          <div class="w-1/3 px-3">
            <p class="text-sm font-medium my-2">Participant</p>
            <div class="flex items-center justify-between">
              <span class="py-2.5 flex items-center justify-center">
                <font-awesome-icon class="text-2xl" icon="fa-solid fa-user" />
              </span>
              <div class="flex">
                <button class="py-1 px-3 bg-f5 flex items-center">
                  <font-awesome-icon class="text-base" icon="fa-solid fa-minus" />
                </button>
                <span class="relative py-5 px-7 mx-2 text-center text-lg font-semibold border border-black rounded-lg">
                  <p class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</p>
                </span>
                <button class="py-1 px-3 bg-f5 flex items-center">
                  <font-awesome-icon class="text-base" icon="fa-solid fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <CardInsurance />
        <CardInsurance />
      </div>
    </div>
  </div>
</template>

<script>
import CardInsurance from '../../components/CardInsurance.vue';

export default {
  name: 'InsuranceList',
  components: {
    CardInsurance
  },
  data() {
    return {
      benefitReady: false,
      setBenefit: new Set(),
      benefit: []
    }
  },
  methods: {
    openBenefit(index) {
      let i = this.benefit.indexOf(index)
    	if (i < 0) {
      	this.toggle.push(index)
      } else {
      	this.toggle.splice(i, 1)
      }
    },
  },
};
</script>
